import revive_payload_client_KbqbutxtiB from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_4usbe4pheih4yopon6pgh32sdm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LzWPaJN856 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_4usbe4pheih4yopon6pgh32sdm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QopZYUT1UE from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_4usbe4pheih4yopon6pgh32sdm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_CWI2dIBREe from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.4_rollup@4.18.0_vue@3.4.27_typescript@5.4.5_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import check_outdated_build_client_fJMzaYZENj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_4usbe4pheih4yopon6pgh32sdm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_yhEUZaoBBs from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_yUHmSHe5iC from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_4usbe4pheih4yopon6pgh32sdm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_olAIjCoT5H from "/vercel/path0/node_modules/.pnpm/@nuxtjs+plausible@1.0.0_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import plugin_6Ph1iQzNHk from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import i18n_aasIzJxNTf from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_6k3Azp2mCW from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import chunk_reload_client_yyYCWEeSUY from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_4usbe4pheih4yopon6pgh32sdm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_auto_pageviews_client_vphDZJHSMq from "/vercel/path0/node_modules/.pnpm/@nuxtjs+plausible@1.0.0_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.mjs";
export default [
  revive_payload_client_KbqbutxtiB,
  unhead_LzWPaJN856,
  router_QopZYUT1UE,
  sentry_client_CWI2dIBREe,
  check_outdated_build_client_fJMzaYZENj,
  plugin_vue3_yhEUZaoBBs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yUHmSHe5iC,
  plugin_client_olAIjCoT5H,
  plugin_6Ph1iQzNHk,
  i18n_aasIzJxNTf,
  plugin_client_6k3Azp2mCW,
  unocss_MzCDxu9LMj,
  chunk_reload_client_yyYCWEeSUY,
  plugin_auto_pageviews_client_vphDZJHSMq
]